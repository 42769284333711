// @flow

//https://www.reverbnation.com/api/artist/2326678/shows 
import * as facebook from "./lib/facebook";
import * as frontpage from "./lib/frontpage";
import * as polyfill from "./lib/polyfill";

polyfill.init();

frontpage.init();

var page = window.location.href;
if (page.includes("gallery")) {
	facebook.init();
}
