
export function init() {
  console.log("Loading facebook photos...");
  $(document).ready(function () {
      // $.get("https://sarahtuckermusic.webscript.io/facebook", function(data) {
        $(".fb-album-container").FacebookAlbumBrowser({
            account: "248991198500071",
            accessToken: "EAALlTqVmjsUBANn7Oi9z2nsYJShziBAXZCXQUKSExLCkNDL39LolquDedPcsdMNZB4P4IBJZCrMncqMFBkzlJFburKF9V2S49hqZCFz7j4uuO63lCG4P6yaVjyFMetvJJE205PvXqB2aen7tfOl6LYCfZAAtiK3AZD"
        });
      // })
  });
}
